:root {
  --primary-color: #747c93;
  --primary-color-hover: #666d80;
  --secondary-color: #31c885;
  --secondary-color-hover: #2ba46e;
  --dark-color: #1a202e;
  --medium-color: rgba(116, 124, 147, 0.4);
}

/* General Styles */
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  height: auto;
  min-height: 100vh;
  position: relative;
  background: #f9faff;
  font-family: 'Raleway', sans-serif;
  color: var(--dark-color);
}

a {
  text-decoration: none;
}

#asg-logo {
  height: 50px;
  width: auto;
}
