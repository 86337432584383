.banner {
  background-color: var(--dark-color);
  background: url('../../img/banner-bg.jpg') no-repeat center center/cover;
  color: #fff;
  padding: 50px 0 80px 0;
  position: relative;
}

.banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: var(--dark-color);
  opacity: 0.8;
  z-index: 1;
}

.banner .container {
  z-index: 10;
  position: inherit;
}

.banner .banner-title {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.banner .banner-content {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.banner .banner-content ol {
  font-size: 18px;
  padding-left: 18px;
}

.banner .banner-content ol li {
  padding-bottom: 12px;
}

.banner .banner-content .banner-video {
  overflow: hidden;
}

/* Banner on newMod page */
.banner-text .btn-primary {
  padding: 16px 64px;
  margin-top: 30px;
}

#upload-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .banner .banner-title {
    font-size: 20px;
  }
}
