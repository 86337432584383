#footer {
  padding: 20px 0;
  border-top: 3px solid var(--primary-color);
  background-color: #fff;
}

#footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer .copy {
  font-size: 14px;
}

#footer .container .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#footer .container .right > * {
  margin-left: 40px;
}

#footer .btn-scroll {
  cursor: pointer;
}

#footer .container .right > *:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 768px) {
  #footer {
    padding: 30px 0;
  }

  #footer .container {
    flex-direction: column;
  }

  #footer .container > * {
    margin-bottom: 30px;
  }

  #footer .container > *:last-child {
    margin-bottom: 0;
  }
}
