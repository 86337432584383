.meta {
  font-size: 16px;
}

.mod-card .header {
  font-size: 18px;
  font-weight: 700;
  color: var(--dark-color);
}

.mod-card .header:hover {
  color: var(--primary-color);
}

.mod-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 70px;
}

.mod-card {
  max-width: 290px;
  border-radius: 4px;
  border: 1px solid var(--medium-color);
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 30px;
}

.mod-card img {
  width: 290px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mod-card .content {
  text-align: center;
  padding: 0 20px;
}

.mod-card .content .meta:first-child {
  padding: 15px 0 9px 0;
}
.mod-card .content .meta:last-child {
  padding: 9px 0 15px 0;
}

.mod-card .extra.content.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 22px;
}

.mod-card .extra.content.buttons .btn-primary,
.mod-card .extra.content.buttons .btn-secondary {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .mod-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
