.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.filter-card {
  max-width: 320px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 28px;
}

.filter-card img {
  width: 320px;
  border-radius: 4px;
}

.ribbon-label {
  position: absolute;
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
  padding: 5px 20px 5px;
  font-size: 12px;
  top: 14px;
  left: -12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ribbon-label::after {
  content: '';
  width: 33px;
  height: 33px;
  background-color: var(--primary-color-hover);
  position: absolute;
  top: 14px;
  left: 6px;
  transform: rotate(-27deg);
  z-index: -4;
}

@media only screen and (max-width: 768px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
