.pagination-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 80px;
}

.pagination a,
.pagination button {
  margin: 0 6.5px;
}

.pagination .page-btn {
  font-size: 20px;
  color: var(--primary-color);
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s ease-in;
}

.pagination .page-btn.active,
.pagination .page-btn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .pagination {
    position: relative;
    min-height: 100px;
  }

  .pagination .btn-primary:first-child {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .pagination .btn-primary:last-child {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
