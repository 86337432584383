.form-review {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 80px;
}

.form-review .header {
  font-size: 28px;
  margin-bottom: 25px;
}

.form-review p {
  color: var(--primary-color);
  font-size: 13px;
}

.form-review .mod-preview {
  margin: 60px 0;
}

.field.large {
  width: 100% !important;
}

.form-review {
}
