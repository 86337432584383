.form-review .mod-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mods-list .contribution {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 80px;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 28px;
}

.preview-img img {
  max-width: 30rem;
  margin-top: 22px;
}

/* Mod Form */
.mod-form {
  max-width: 700px;
  margin: 60px auto 80px;
}

.mod-form .btn-secondary {
  max-width: 192px;
  margin-bottom: 22px;
}

.mod-form .btn-secondary div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mod-form .btn-secondary div img {
  padding-left: 7px;
}

.mod-form .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mod-form .field label:not(.btn-secondary) {
  padding-bottom: 11px;
  font-size: 15px;
  color: var(--primary-color);
}

input#files {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
}

input#modImage {
  display: none;
}

#files-label {
  position: relative;
}

input[type='text'],
input[type='number'] {
  word-wrap: break-word;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 300px;
  min-height: 40px;
  background: #fff;
  display: inline-block;
  padding: 10px 12px;
  color: var(--dark-color);
  box-shadow: none;
  border: 1px solid var(--medium-color);
  border-radius: 4px;
  transition: width 0.1s ease;
  font-size: 16px;
}

.files-list {
  margin-top: 30px;
}

.files-list ul {
  list-style: none;
  max-width: 900px;
  position: relative;
}

.files-list li {
  display: inline;
  padding-right: 45px;
  font-size: 15px;
  color: var(--primary-color);
}

form label#files-label {
  padding: 24px;
  width: 100%;
  max-width: 100%;
  height: 200px;
  display: inline-block;
  border: 2px dashed var(--secondary-color);
  font-size: 16px;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  form label#files-label {
    display: none;
  }
}
