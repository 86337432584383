.hero {
  background: url('../../img/hero.jpg') no-repeat center center/cover;
  color: #fff;
  max-width: 100vw;
  min-height: 768px;
  max-height: 55rem;
  overflow: hidden;
}

.hero .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero h1.title {
  font-size: 80px;
  text-align: center;
  max-width: 656px;
  margin: 0 auto;
  margin-top: 60px;
}

.hero .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
  max-width: 300px;
}

.hero .form .field {
  margin-bottom: 12px;
  width: 100%;
}

.hero .form .field label {
  display: block;
  padding-bottom: 10px;
}

.home-page.filter-pages {
  margin-top: 80px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 768px) {
  .hero h1.title {
    font-size: 12vw;
    margin-bottom: 60px;
  }
}
