.ui.grid.brands-grid {
  width: 100%;
}

.ui.grid.brands-grid > [class*='five wide'].column {
  width: 93% !important;
  margin: 0 auto;
}

.ui.grid.brands-grid > [class*='five wide'].column .column {
  background-color: #fff;
}

.brands-grid {
  margin-top: 1rem !important;
}

.brands-grid [class*='five wide'].column {
  height: 12rem;
  display: block;
}

.brands-grid [class*='five wide'].column .column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.brands-grid .ui.fluid.image img {
  width: 50%;
  max-height: 100%;
  margin: 0 auto;
}
